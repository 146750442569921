class SummaryHandler {
    constructor ($module) {
        this.$summary = $module;
        this.itemPrices = {};
    }

    changeContent (target, content) {
        const $target = this.$summary.querySelector('[data-' + target + ']');
        $target.innerHTML = content;
    }

    addEDevice ($button) {
        const deviceName = $button.getAttribute('data-name');
        const deviceId = $button.getAttribute('data-id');
        if (!this.$summary.querySelector('[data-' + deviceId + ']')) {
            const deviceType = $button.getAttribute('data-type');
            let exclusivity = '';
            if ($button.getAttribute('data-exclusive') !== null) {
                exclusivity = $button.getAttribute('data-exclusive');
            } else if ($button.getAttribute('data-inclusive') !== null) {
                exclusivity = $button.getAttribute('data-inclusive');
            }

            const $tbody = this.$summary.querySelector('[data-summary-e-device]');

            const $tr = document.createElement('tr');

            $tr.innerHTML = '<th>' + deviceType + '</th>' +
                '<td>' +
                    '<div class="cell-flex"><span>' + deviceName + '</span><span>' + exclusivity + '</span></div>' +
                '</td>';

            $tr.setAttribute('data-' + deviceId, '');

            $tbody.appendChild($tr);
        }
    }

    removeEDevice ($button) {
        const deviceId = $button.getAttribute('data-id');
        const $tr = this.$summary.querySelector('[data-' + deviceId + ']');
        $tr.parentNode.removeChild($tr);
    }

    // add or remove price from the itemPrice Object
    addPrice (key, value) {
        this.itemPrices[key] = value;
        this.printPrice();
    }

    removePrice (key) {
        delete this.itemPrices[key];
        this.printPrice();
    }

    // calculates the price of the object containing the prices of the items
    calculatePrice () {
        let sum = 0;
        const obj = this.itemPrices;

        for (const i in obj) {
            // convert to float
            const price = parseFloat(obj[i]) ? parseFloat(obj[i]) : 0;
            sum += price;
        }
        sum = sum.toFixed(2);
        return sum;
    }

    // to remove the price, negate the price
    printPrice () {
        // if data price is not empty, then update price
        const $priceField = this.$summary.querySelector('[data-exclusive-price]');

        if ($priceField) {
            const price = this.calculatePrice().toString().replace('.', ',');
            $priceField.firstChild.data = price;
        }
    }
}

export { SummaryHandler };
